/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from "react";
import styles from "./Media.module.scss";

const Image = ({ item, onClick }) => {
  const [isLoad, setLoad] = useState(true);
  return (
    <div
      className={
        styles.media_cards +
        " " +
        styles.media_cards__photo +
        " " +
        styles.visible
      }
      onClick={() => onClick(item)}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
      }}
    >
      {isLoad && (
        <p style={{ textAlign: "center", position: "absolute" }}>Загрузка...</p>
      )}
      <img
        src={item.file}
        onLoad={() => setLoad(false)}
        style={isLoad ? { opacity: 0 } : { opacity: 1 }}
      />
    </div>
  );
};

export default Image;
