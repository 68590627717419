
/* eslint-disable jsx-a11y/iframe-has-title */
import React from 'react';
import styles from './LiveBroadcast.module.scss';

const LiveBroadcast = ({ colorThemeRedux }) => {

  return (
    <section className={styles.section}>
      <h2 className={styles.title + " " + (colorThemeRedux ? styles.lowVisibleVersion : "")}>Прямая трансляция гала-концерта Фестиваля мужских хоров</h2>
      <iframe className={styles.video} src="https://vk.com/video_ext.php?oid=-128042965&id=456240395&hash=bff8b976886c1444" width="640" height="360" frameborder="0" allowfullscreen="1" allow="autoplay; encrypted-media; fullscreen; picture-in-picture" />
    </section>
  )
}

export default LiveBroadcast
