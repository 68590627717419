import React, { useState, useEffect } from "react";
import styles from "./Experts.module.scss";
import Container from "../../../templates/Container";
import { getExperts } from "../../../../redux/requests/getExperts";
import UseWindowSize from "../../../templates/UseWindowSize/UseWindowSize";
import Slider from "react-slick";
import "../../slider.scss";
import { useSelector } from "react-redux";
import ExpertItem from "./ExpertItem.jsx";

const Experts = () => {
  const [data, setData] = useState();
  const [width] = UseWindowSize();

  const colorThemeRedux = useSelector((state) => state.theme.colorTheme);


  useEffect(() => {
    getExperts().then((res) => {
      setData(res);
    });
  }, []);

  let settings = {
    arrows: true,
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    fade: true,
    adaptiveHeight: true,
    accessibility: true,
  };

  return (
    <section className={styles.experts} id="experts">
      <Container>
        <h2
          className={styles.section_title}
          style={
            colorThemeRedux
              ? { color: "#000", fontSize: 30, marginBottom: 20 }
              : {}
          }
        >
          Экспертный совет
        </h2>

        {!colorThemeRedux && <div className={styles.separator} />}

        {width > 1070 ? (
          <div
            className={styles.cards}
            style={
              colorThemeRedux ? { gridTemplateColumns: "repeat(1, 1fr)" } : {}
            }
          >
            {data?.results.map((card) => <ExpertItem card={card} key={card.id} />)}
          </div>
        ) : (
          <Slider {...settings}>
            {data?.results.map((card) => (
              <ExpertItem card={card} key={card.id} />
            ))}
          </Slider>
        )}
      </Container>

      <div className={styles.decor} />
    </section>
  );
};

export default Experts;
