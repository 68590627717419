import React, { useState, useEffect } from "react";
import styles from "./Chiefs.module.scss";
import Container from "../../../templates/Container";
import { getChiefs } from "../../../../redux/requests/getChiefs";
import UseWindowSize from "../../../templates/UseWindowSize/UseWindowSize";
import Slider from "react-slick";
import "../../slider.scss";
import { useSelector } from "react-redux";
import ChiefsItem from "./ChiefsItem";

const Chiefs = () => {
  const [words, setWords] = useState();
  const [width] = UseWindowSize();

  const colorThemeRedux = useSelector((state) => state.theme.colorTheme);

  useEffect(() => {
    getChiefs().then((res) => {
      setWords(res);
    });
  }, []);

  let settings = {
    adaptiveHeight: true,

    arrows: true,
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    fade: true,
    // accessibility: true,
  };

  return (
    <section className={styles.chiefs} id="chiefs">
      <h2
        className={styles.section_title}
        style={
          colorThemeRedux
            ? { color: "#000", fontSize: 30, marginBottom: 20 }
            : {}
        }
      >
        Приветственные слова
      </h2>

      {width > 1070 ? (
        <Container>
          <div className={styles.cards}>
            {words?.results.map((card) => (
              <ChiefsItem card={card} key={card.id} />
            ))}
          </div>
        </Container>
      ) : (
        <Slider {...settings}>
          {words?.results.map((card) => (
            <ChiefsItem card={card} key={card.id} />
          ))}
        </Slider>
      )}

      {!colorThemeRedux && <div className={styles.decor} />}
    </section>
  );
};

export default Chiefs;
