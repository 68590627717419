/* eslint-disable no-unused-vars */
import React from "react";
import styles from "./Head.module.scss";
import Family from "../../../assets/logo/family_.svg";
import Container from "../../templates/Container";
import { useLocation } from "react-router-dom";
// import HeadEvent from "../HeadEvent/HeadEvent";
import LogoKray from "../../../assets/logo/logo_90_kray.svg";

// import UseWindowSize from '../../templates/UseWindowSize/UseWindowSize';
import { useSelector } from "react-redux";

const Head = () => {
  const location = useLocation();

  const colorThemeRedux = useSelector((state) => state.theme.colorTheme);

  // const headerEvents = [
  //   {
  //     title:
  //       "<p>КОНЦЕРТ, ПОСВЯЩЕННЫЙ 150-ЛЕТИЮ</br>СО ДНЯ РОЖДЕНИЯ С.В.&nbsp;РАХМАНИНОВА</br>И 150-ЛЕТИЮ СО ДНЯ РОЖДЕНИЯ Ф.И.&nbsp;ШАЛЯПИНА</p>",
  //     date: "15 ИЮНЯ 2023 ГОДА",
  //     time: "В 19:00",
  //     link: "https://krasfil.ru/events/buy/16116",
  //   },
  //   {
  //     title: "<p>ТВОРЧЕСКАЯ ВСТРЕЧА-КОНЦЕРТ «АВЕ&nbsp;МАРИЯ»</p>",
  //     date: "18 ИЮНЯ 2023 ГОДА",
  //     time: "В 19:00",
  //     link: "https://krasfil.ru/events/buy/16121",
  //   },
  //   {
  //     title: "<p>ГАЛА-КОНЦЕРТ</p>",
  //     date: "19 ИЮНЯ 2023 ГОДА",
  //     time: "В 19:00",
  //     link: "https://krasfil.ru/events/buy/16115",
  //   },
  // ];

  return location.pathname === "/" ? (
    <div
      style={{
        height: colorThemeRedux ? "" : "80vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
      }}
      className={styles.head_mobile_wrap + ' ' + (colorThemeRedux ? styles.lowVisibleFmh : "")}
    >
      <Container>
        <section
          className={
            styles.fmh + " " + (colorThemeRedux ? styles.lowVisibleFmh : "")
          }
        >
          {!colorThemeRedux && (
            <div className={styles.fmh__logo + " " + styles.fmh__logo_right}>
              <img
                src={Family}
                alt="Год Семьи"
              />
            </div>
          )}

          <div className={styles.supports_text}>
            <p>ПОД ПАТРОНАТОМ ГУБЕРНАТОРА КРАСНОЯРСКОГО КРАЯ М.М. КОТЮКОВА</p>
            <p style={{ marginBottom: 20 }}>
              ПО БЛАГОСЛОВЕНИЮ МИТРОПОЛИТА КРАСНОЯРСКОГО И АЧИНСКОГО ПАНТЕЛЕИМОНА
            </p>
            <p>МИНИСТЕРСТВО КУЛЬТУРЫ КРАСНОЯРСКОГО КРАЯ</p>
            <p style={{ marginBottom: 20 }}>СТРУКТУРНОЕ ПОДРАЗДЕЛЕНИЕ ГЦНТ — КУЛЬТУРНО-ИСТОРИЧЕСКИЙ ЦЕНТР</p>
            <p>ПРИ СОДЕЙСТВИИ</p>
            <p>ФЕДЕРАЦИИ МУЖСКИХ ХОРОВ И АНСАМБЛЕЙ</p>
            <p>ГОСУДАРСТВЕННОГО РОССИЙСКОГО ДОМА НАРОДНОГО ТВОРЧЕСТВА ИМЕНИ В.Д. ПОЛЕНОВА</p>
          </div>

          {!colorThemeRedux && (
            <div
              className={styles.fmh__logo + " " + styles.fmh__logo_left}
              style={{ justifyContent: "flex-end" }}
            >
              <img
                src={LogoKray}
                alt="90 лет Красноярскому краю"
                style={{ aspectRatio: "1/1" }}
              />
            </div>
          )}
        </section>
      </Container>
      <Container>
        <p className={styles.fmh__title}>
          <span
            className={
              styles.fmh__title_text +
              " " +
              styles.fmh__title_text_1 +
              " " +
              (colorThemeRedux ? styles.lowVisibleVersion : "")
            }
          >
            Фестиваль мужских&nbsp;хоров
          </span>
          <span
            className={
              styles.fmh__title_text +
              " " +
              styles.fmh__title_text_2 +
              " " +
              (colorThemeRedux ? styles.lowVisibleVersion : "")
            }
          >
            Фестиваль мужских&nbsp;хоров
          </span>
        </p>
      </Container>
      <Container>
        <div className={
          styles.head_events + " " + (colorThemeRedux ? styles.lowVisibleFmh : "")
          } />
        {/* <div
          className={styles.head_events}
          style={colorThemeRedux ? { marginBottom: 70 } : {}}
        >
          {headerEvents.map((item, index) => (
            <HeadEvent key={index} event={item} />
          ))}
        </div> */}
      </Container>
      <Container>
        <div className={styles.mobile_fmh_logos}>
          <div className={styles.fmh__logo + " " + styles.fmh__logo_right}>
            <img
              src={Family}
              alt="Год семьи"
            />
          </div>
          <div
            className={styles.fmh__logo + " " + styles.fmh__logo_left}
            style={{ justifyContent: "flex-end" }}
          >
            <img
              src={LogoKray}
              alt="90 лет Красноярскому краю"
              style={{ aspectRatio: "1/1" }}
            />
          </div>
        </div>
      </Container>

      {!colorThemeRedux && <div className={styles.bottom_border}></div>}
    </div>
  ) : (
    <Container>
      <section
        className={
          styles.fmh + " " + (colorThemeRedux ? styles.lowVisibleFmh : "")
        }
      >
        {!colorThemeRedux && (
          <div className={styles.fmh__logo + " " + styles.fmh__logo_right}>
            <img
              src={Family}
              alt="Год семьи"
            />
          </div>
        )}

        <p className={styles.fmh__title} style={{ flex: 1 }}>
          <span
            className={
              styles.fmh__title_text +
              " " +
              styles.fmh__title_text_1 +
              " " +
              (colorThemeRedux ? styles.lowVisibleVersion : "")
            }
          >
            Фестиваль мужских&nbsp;хоров
          </span>
          <span
            className={
              styles.fmh__title_text +
              " " +
              styles.fmh__title_text_2 +
              " " +
              (colorThemeRedux ? styles.lowVisibleVersion : "")
            }
          >
            Фестиваль мужских&nbsp;хоров
          </span>
        </p>

        {!colorThemeRedux && (
          <div
            className={styles.fmh__logo + " " + styles.fmh__logo_left}
            style={{ justifyContent: "flex-end" }}
          >
            <img
              src={LogoKray}
              alt="90 лет Красноярскому краю"
              style={{ aspectRatio: "1/1" }}
            />
          </div>
        )}
      </section>
    </Container>
  );
};

export default Head;
