import React from "react";
import styles from "./History.module.scss";
import PageTemplate from "../../templates/PageTemplate";
import Container from "../../templates/Container";
import Head from "../../elements/Head/Head";

import { ReactComponent as VenzelFive } from "../../../assets/svg/venzel_5_2color.svg";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const Press = () => {
  const colorThemeRedux = useSelector((state) => state.theme.colorTheme);

  return (
    <>
      <PageTemplate>
        <Head />

        <Container>
          <section className={styles.history}>
            <h1
              className={styles.title}
              style={colorThemeRedux ? { fontSize: 30, color: "#000" } : {}}
            >
              Пресс-релиз
            </h1>
            {!colorThemeRedux && <div className={styles.separator} />}

            <div
              className={
                styles.frame +
                " " +
                (colorThemeRedux ? styles.lowVisibleVersion : "")
              }
            >
              <div className={styles.content}>
                <p>
                  С 12 по 21 июня 2023 года в Красноярском крае во второй раз
                  состоится знаменательное событие, направленное на возрождение
                  традиционной национальной культуры, – Фестиваль мужских хоров.
                </p>
                <p>
                  Организатором Фестиваля является структурное подразделение
                  ГЦНТ – Культурно-исторический центр.
                </p>
                <p>
                  Учредитель Фестиваля – министерство культуры Красноярского
                  края.
                </p>
                <p>
                  В рамках события состоится 40 мероприятий, которые посетят
                  более 15 000 человек, в том числе 20 концертных программ будут
                  представлены в муниципальных образованиях Красноярского края:
                  Ачинск, Березовка, Боготол, Бородино, Большая Мурта,
                  Дивногорск, Емельяново, Железногорск, Зеленогорск, Иланский,
                  Казачинское, Канск, Каратузское, Минусинск, Назарово,
                  Новосёлово, Сосновоборск, Уяр, Шарыпово, Шушенское.
                </p>
                <p>
                  В концертных программах Фестиваля примут участие прославленные
                  мужские и детско-юношеские хоры из Армении, Сербии, Беларуси,
                  Воронежа, Екатеринбурга, Грозного, Иваново, Королёва,
                  Красноярска, Москвы, Санкт-Петербурга, Северной Осетии-Алании,
                  Симферополя, Твери и др.
                </p>
                <p>
                  15 июня 2023 года в 19:00 в Малом концертном зале им. И.В.
                  Шпиллера Красноярской краевой филармонии состоится концерт,
                  посвящённый 150-летию со дня рождения С.В. Рахманинова и Ф.И.
                  Шаляпина. В программе концерта прозвучат произведения великого
                  русского композитора Сергея Васильевича Рахманинова, арии из
                  опер и народные песни из репертуара выдающегося русского певца
                  Фёдора Ивановича Шаляпина, которые будут исполнены участниками
                  Фестиваля: солистами-басами, солистами-баритонами и хорами.
                </p>
                <p>
                  18 июня 2023 года в 19:00 в Органном зале Красноярской краевой
                  филармонии для зрителей будет представлена творческая
                  встреча-концерт «Аве Мария», на которой прозвучат произведения
                  выдающихся зарубежных, российских, в том числе красноярских,
                  композиторов: Франца Шуберта, Антона Брукнера, Григория
                  Григорьева, Джулио Каччини, Владимира Вавилова, Камиля
                  Сен-Санса, Владимира Пономарева и других, восхваляющих образ
                  Пречистой Девы Марии.
                </p>
                <p>
                  19 июня 2023 года в 19:00 в Большом концертном зале
                  Красноярской краевой филармонии состоится гала-концерт
                  Фестиваля мужских хоров, на котором ведущие хоровые коллективы
                  мирового уровня продемонстрируют свое мастерство. В финале
                  концертной программы сводный хор из более чем 600 мужчин и
                  юношей – участников Фестиваля исполнит музыкальные шедевры
                  русской культуры.
                </p>
                <p>
                  Билеты на концерты можно приобрести в кассах Красноярской
                  краевой филармонии и на официальном сайте{" "}
                  <Link
                    to="http://www.krasfil.ru"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    www.krasfil.ru
                  </Link>
                  .
                </p>
                <p>
                  Прямая трансляция гала-концерта будет проходить 19 июня 2023
                  года в 19:00 на официальном сайте –{" "}
                  <Link
                    to="http://фестивальмужскиххоров.рф"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    фестивальмужскиххоров.рф
                  </Link>
                  .
                </p>
                <br />
                <p>
                  <i>
                    Дополнительная информация для представителей СМИ по тел.{" "}
                    <Link to="tel:+79232703311">8-923-270-33-11</Link> или e-mail:{" "}
                    <Link to="mailto:vassaperova@mail.ru">vassaperova@mail.ru</Link>{" "}
                    – Перова Василиса Владимировна, pr-менеджер
                    Культурно-исторического центра «Успенский».
                  </i>
                </p>
              </div>

              <VenzelFive />
              <VenzelFive />
              <VenzelFive />
              <VenzelFive />
            </div>

            {!colorThemeRedux && (
              <div className={styles.separator + " " + styles.separator_down} />
            )}
          </section>
        </Container>
      </PageTemplate>
    </>
  );
};

export default Press;
