import { BASE_URL } from "../API/BASE_URL";

export const getNews = async () => {
  const response = await fetch(BASE_URL + "news/news/", {
    method: "GET",
  });

  const data = await response.json();

  return data;
};

export const getNewsById = async (id) => {
  const response = await fetch(BASE_URL + "news/news/" + id + "/", {
    method: "GET",
  });

  const data = await response.json();

  return data;
};
