import { BASE_URL } from "../API/BASE_URL";

export const getVideo = async (id) => {
  const response = await fetch(BASE_URL + `media/videos/`, {
    method: "GET",
  });

  const data = await response.json();

  return data;
};
