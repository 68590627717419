import React from "react";

import styles from "./SingleNews.module.scss";
import { Link } from "react-router-dom";

const SingleNews = ({ news }) => {
  const formateDate = (date) => {
    const dateLocal = new Date(date);

    const day =
      dateLocal.getDate() < 10
        ? "0" + dateLocal.getDate()
        : dateLocal.getDate();

    const month =
      dateLocal.getMonth() + 1 < 10
        ? "0" + (dateLocal.getMonth() + 1)
        : dateLocal.getMonth() + 1;

    return `${day}.${month}.${dateLocal.getFullYear()} `;
  };
  return (
    <div className={styles.item}>
      <img src={news.photo} alt="" />
      <div className={styles.text_wrapper}>
        <div className={styles.text}>
          <h3>{news.title}</h3>
          <p className={styles.text_preview}>{news.short_description}</p>
          <span>{news.date}</span>
        </div>
        <div className={styles.control}>
          <Link to={"/news/" + news.id}>Подробнее</Link>
          <span>{formateDate(news.datetime)}</span>
        </div>
      </div>
    </div>
  );
};

export default SingleNews;
