import React from "react";
import PageTemplate from "../../templates/PageTemplate";
import Head from "../../elements/Head/Head";

import Chiefs from "./Chiefs/Chiefs";
import Experts from "./Experts/Experts";
import AboutState from "./AboutState/AboutState";
import Partners from "./Partners/Partners";
import Socials from "../../elements/Socials/Socials";

const MainPage = () => {
  return (
    <>
      <PageTemplate>
        <Head />
        <Chiefs />
        <Experts />
        <AboutState />
        <Partners />
        <Socials separator={true} />
      </PageTemplate>
    </>
  );
};

export default MainPage;
