import React from "react";
import styles from "./History.module.scss";
import PageTemplate from "../../templates/PageTemplate";
import Container from "../../templates/Container";
import Head from "../../elements/Head/Head";

import { ReactComponent as VenzelFive } from "../../../assets/svg/venzel_5_2color.svg";
import { useSelector } from "react-redux";

const History = () => {
  const colorThemeRedux = useSelector((state) => state.theme.colorTheme);

  return (
    <>
      <PageTemplate>
        <Head />

        <Container>
          <section className={styles.history}>
            <h1
              className={styles.title}
              style={colorThemeRedux ? { fontSize: 30, color: "#000" } : {}}
            >
              История фестиваля
            </h1>
            {!colorThemeRedux && <div className={styles.separator} />}

            <div
              className={
                styles.frame +
                " " +
                (colorThemeRedux ? styles.lowVisibleVersion : "")
              }
            >
              <div className={styles.content}>
                <p>
                  Со 02 по 12 сентября 2024 года в&nbsp;Красноярском крае, географическом центре России, в&nbsp;третий раз состоится знаменательное событие, направленное на&nbsp;возрождение традиционной национальной культуры, – Фестиваль мужских хоров.
                </p>
                <p>
                  За десять дней Фестиваля пройдёт более 50 мероприятий, в&nbsp;том числе свыше 30 концертных программ будут представлены в&nbsp;муниципальных образованиях Красноярского края, на&nbsp;которых выступят прославленные мужские хоры из&nbsp;различных субъектов Российской Федерации (Республика Крым, Республика Северная Осетия-Алания, Республика Татарстан, Чеченская Республика, Красноярский край, Воронежская область, Московская область, гг.&nbsp;Москва, Санкт-Петербург, Севастополь), а&nbsp;также зарубежья – Грузия, Китайская Народная Республика, Республика Армения, Республика Беларусь, Республика Казахстан, Республика Сербия.
                </p>
                <p>
                  Гала-концерт Фестиваля состоится 09 сентября 2024 года в&nbsp;19:00 в&nbsp;Большом концертном зале Красноярской краевой филармонии. В&nbsp;финале концертной программы сводный хор из&nbsp;более чем 700 мужчин, юношей и&nbsp;детей исполнит музыкальные шедевры русской культуры.
                </p>
              </div>

              <VenzelFive />
              <VenzelFive />
              <VenzelFive />
              <VenzelFive />
            </div>

            {!colorThemeRedux && (
              <div className={styles.separator + " " + styles.separator_down} />
            )}
          </section>
        </Container>
      </PageTemplate>
    </>
  );
};

export default History;
