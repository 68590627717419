import React from 'react';
import styles from "./Chiefs.module.scss";
import { useSelector } from 'react-redux';
import VoiceAudioButton from '../../../elements/VoiceAudioButton/VoiceAudioButton';


const ChiefsItem = ({ card }) => {
  const colorThemeRedux = useSelector((state) => state.theme.colorTheme);

  return (
    <div className={styles.card} key={card.id}>
      <div className={styles.photo}>
        <img src={card.photo} alt={card.title} />
        <VoiceAudioButton link={card.voice} />
      </div>
      <p
        className={styles.jobtitle}
        style={
          colorThemeRedux
            ? { color: "#000", fontSize: 30, marginBottom: 20 }
            : {}
        }
      >
        {card.position}
      </p>
      <p
        className={styles.name}
        style={
          colorThemeRedux
            ? { color: "#000", fontSize: 30, marginBottom: 20 }
            : {}
        }
      >
        {card.fio}
      </p>
      <p
        className={styles.desc}
        style={colorThemeRedux ? { color: "#000", fontSize: 30 } : {}}
      >
        {card.text}
      </p>
    </div>
  )
}

export default ChiefsItem;
