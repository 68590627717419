/* eslint-disable react/jsx-pascal-case */
import React, { useState, useEffect } from "react";
import Burger from "./Burger/Burger";
import styles from "./Header.module.scss";

import { ReactComponent as VenzelFive } from "../../../assets/svg/venzel_5_2color.svg";
import { ReactComponent as Arrow_up } from "../../../assets/svg/Arrow_up.svg";

import UseWindowSize from "../../templates/UseWindowSize/UseWindowSize";
import NavMainMenu from "./NavMainMenu/NavMainMenu";
import NavMainMenuMobile from "./NavMainMenuMobile/NavMainMenuMobile";
import { useSelector } from "react-redux";

const Header = () => {
  const [width] = UseWindowSize();
  const [burgerActive, setBurgerActive] = useState(false);
  const [subMenuActive, setSubMenuActive] = useState(false);

  useEffect(() => {
    burgerActive
      ? document.body.classList.add("nonScroll")
      : document.body.classList.remove("nonScroll");
  }, [burgerActive]);

  // Убираются лишние паддинги при скролле
  const [scroll, setScroll] = useState(false);

  function handleScroll() {
    let scrollDistance = window.scrollY;

    if (scrollDistance > 50) {
      setScroll(true);
    } else {
      setScroll(false);
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    // удаляется слушатель событий чтобы не засирать оперативную память (иначе создается куча слушателей при перелистывании страниц)
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });

  const colorThemeRedux = useSelector((state) => state.theme.colorTheme);

  return (
    <>
      <header
        className={
          styles.header +
          " " +
          (colorThemeRedux ? styles.lowVisibleVersion : "")
        }
        id="header"
      >
        {width > 1070 ? (
          <NavMainMenu
            subMenuActive={subMenuActive}
            setSubMenuActive={setSubMenuActive}
          />
        ) : (
          <NavMainMenuMobile
            burgerActive={burgerActive}
            setBurgerActive={setBurgerActive}
          />
        )}

        {!colorThemeRedux && (
          <div className={styles.header__decorate}>
            <VenzelFive />
            <VenzelFive />
            <VenzelFive />
            <VenzelFive />
          </div>
        )}

        <Burger
          burgerActive={burgerActive}
          setBurgerActive={setBurgerActive}
          scroll={scroll}
        />

        <div
          className={
            scroll
              ? styles.header__up_button
              : styles.header__up_button + " " + styles.hidden
          }
        >
          <Arrow_up
            onClick={() => {
              window.scrollTo({
                top: 0,
                left: 0,
                behavior: "smooth",
              });
            }}
          />
        </div>
      </header>
    </>
  );
};

export default Header;
