/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import { Link, NavLink } from "react-router-dom";
import styles from "./NavMainMenu.module.scss";
import { ReactComponent as VenzelFive } from "../../../../assets/svg/venzel_5_2color.svg";
import { useSelector } from "react-redux";
import LowVisibleButton from "../LowVisibleButton";

import arrowDown from "../../../../assets/svg/arrow-down.png";

const NavMainMenu = ({ subMenuActive, setSubMenuActive }) => {
  const colorThemeRedux = useSelector((state) => state.theme.colorTheme);
  return (
    <nav
      className={
        styles.header__nav +
        " " +
        (colorThemeRedux ? styles.lowVisibleVersion : "")
      }
    >
      <ul className={styles.header__list}>
        <LowVisibleButton />
        <li
          className={
            (colorThemeRedux ? styles.header__item_low_visible : "") +
            " " +
            styles.header__item
          }
        >
          <NavLink
            to="/"
            className={({ isActive }) =>
              (isActive ? styles.pressed : "") +
              " " +
              styles.header__link +
              " " +
              (colorThemeRedux ? styles.header__item_low_visible : "")
            }
          >
            <span>Главная</span>
          </NavLink>
        </li>

        <li
          className={
            (colorThemeRedux ? styles.header__item_low_visible : "") +
            " " +
            styles.header__item +
            " " +
            styles.header__item__aboutfest
          }
        >
          <button
            className={styles.header__link + " " + styles.header__link__fest}
            type="button"
            onClick={() => {
              subMenuActive ? setSubMenuActive(false) : setSubMenuActive(true);
            }}
          >
            <span>О фестивале</span>
            {colorThemeRedux && (
              <img src={arrowDown} style={{ marginLeft: 10 }} />
            )}
          </button>

          <div
            className={
              subMenuActive
                ? styles.list_secondary + " " + styles.list_secondary__visible
                : styles.list_secondary
            }
          >
            <div className={styles.list_secondary_frame}>
              <ul>
                <li
                  className={
                    styles.prog +
                    " " +
                    (colorThemeRedux ? styles.header__item_low_visible : "")
                  }
                >
                  <NavLink
                    to="/"
                    className={({ isActive }) =>
                      isActive ? styles.pressed : ""
                    }
                    onClick={() => {
                      subMenuActive
                        ? setSubMenuActive(false)
                        : setSubMenuActive(true);
                    }}
                  >
                    <span>Программа</span>
                  </NavLink>
                </li>

                <li>
                  <NavLink
                    to="/news"
                    onClick={() => {
                      subMenuActive
                        ? setSubMenuActive(false)
                        : setSubMenuActive(true);
                    }}
                  >
                    <span>Новости</span>
                  </NavLink>
                </li>

                <li>
                  <NavLink
                    to="/history"
                    onClick={() => {
                      subMenuActive
                        ? setSubMenuActive(false)
                        : setSubMenuActive(true);
                    }}
                  >
                    <span>История Фестиваля</span>
                  </NavLink>
                </li>

                <li className={styles.item + " " + styles.file}>
                  <Link
                    to={require("../../../../assets/files/Programma.pdf")}
                    target="_blank"
                    download={true} rel="noreferrer"
                  >
                    <span>Программа Фестиваля</span>
                  </Link>
                </li>

                <li>
                  <NavLink
                    to="/press"
                    onClick={() => {
                      subMenuActive
                        ? setSubMenuActive(false)
                        : setSubMenuActive(true);
                    }}
                  >
                    <span>Пресс-релиз</span>
                  </NavLink>
                </li>

                <li>
                  <NavLink
                    to="/contacts"
                    onClick={() => {
                      subMenuActive
                        ? setSubMenuActive(false)
                        : setSubMenuActive(true);
                    }}
                  >
                    <span>Контакты</span>
                  </NavLink>
                </li>
                <li className={styles.file}>
                  <Link
                    to={require("../../../../assets/files/polozhenie.pdf")}
                    target="_blank"
                    download={true}
                    onClick={() => {
                      subMenuActive
                        ? setSubMenuActive(false)
                        : setSubMenuActive(true);
                    }} rel="noreferrer"
                  >
                    <span>Положение о&nbsp;Фестивале</span>
                  </Link>
                </li>
              </ul>

              <VenzelFive />
              <VenzelFive />
              <VenzelFive />
              <VenzelFive />
            </div>
          </div>

          <div
            className={
              subMenuActive
                ? styles.overlay__active + " " + styles.overlay
                : styles.overlay
            }
            onClick={() => {
              subMenuActive ? setSubMenuActive(false) : setSubMenuActive(true);
            }}
          />
        </li>
        <li
          className={
            styles.header__item +
            " " +
            styles.header__item__chiefs +
            " " +
            styles.desktop_hide
          }
        >
          <NavLink
            to="#"
            className={({ isActive }) =>
              (isActive ? styles.pressed : "") + " " + styles.header__link
            }
          >
            <span>Приветственные слова</span>
          </NavLink>
        </li>
        <li
          className={
            styles.header__item +
            " " +
            (colorThemeRedux ? styles.header__item_low_visible : "")
          }
        >
          <NavLink
            to="/participants"
            className={({ isActive }) =>
              (isActive ? styles.pressed : "") + " " + styles.header__link
            }
          >
            <span>Участники</span>
          </NavLink>
        </li>
        <li
          className={
            styles.header__item +
            " " +
            styles.header__item__kr +
            " " +
            styles.desktop_hide
          }
        >
          <NavLink
            to="#"
            className={({ isActive }) =>
              (isActive ? styles.pressed : "") + " " + styles.header__link
            }
          >
            <span>Красноярский край</span>
          </NavLink>
        </li>
        <li
          className={
            styles.header__item +
            " " +
            styles.header__item__media +
            " " +
            (colorThemeRedux ? styles.header__item_low_visible : "")
          }
        >
          <NavLink
            to="/gallery"
            className={({ isActive }) =>
              (isActive ? styles.pressed : "") + " " + styles.header__link
            }
          >
            <span>Медиа</span>
          </NavLink>
        </li>
      </ul>
    </nav>
  );
};

export default NavMainMenu;
