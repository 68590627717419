/* eslint-disable react/jsx-pascal-case */
import React from "react";
import styles from "./Socials.module.scss";
import Container from "../../templates/Container";

import { ReactComponent as LinkVk } from "../../../assets/logo/vk.svg";
import { ReactComponent as LinkOk } from "../../../assets/logo/ok.svg";

import { ReactComponent as LinkVk_black } from "../../../assets/logo/vk-black.svg";
import { ReactComponent as LinkOk_black } from "../../../assets/logo/ok-black.svg";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const Socials = ({ separator }) => {
  const colorThemeRedux = useSelector((state) => state.theme.colorTheme);
  return (
    <section className={styles.socials}>
      <h2 className="visually-hidden">Социальные сети</h2>
      <Container>
        {separator && !colorThemeRedux && <div className={styles.separator} />}

        <div className={styles.wrapper}>
          <Link
            to="https://vk.com/kicuspensky"
            className={styles.item}
            target="_blank"
          >
            <span className="visually-hidden">ссылка вконтакте</span>
            {colorThemeRedux ? (
              <LinkVk_black width={100} height={100} />
            ) : (
              <LinkVk width={30} height={30} />
            )}
          </Link>

          <Link
            to="https://ok.ru/kicuspensky"
            className={styles.item}
            target="_blank"
          >
            <span className="visually-hidden">ссылка на одноклассники</span>
            {colorThemeRedux ? (
              <LinkOk_black width={100} height={100} />
            ) : (
              <LinkOk width={30} height={30} />
            )}
          </Link>
        </div>
      </Container>
    </section>
  );
};

export default Socials;
