const initialState = {
  colorTheme: false,
};

const CHANGE_COLOR_THEME = "CHANGE_COLOR_THEME";

export const ColorThemeReducer = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_COLOR_THEME:
      return {
        colorTheme: action.payload,
      };
    default:
      return state;
  }
};

export const changeThemeAction = (payload) => ({
  type: CHANGE_COLOR_THEME,
  payload: payload,
});
