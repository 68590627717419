import React from 'react';
import styles from "./Experts.module.scss";
import { useSelector } from 'react-redux';
import VoiceAudioButton from '../../../elements/VoiceAudioButton/VoiceAudioButton.jsx';

const ExpertItem = ({ card }) => {
  const colorThemeRedux = useSelector((state) => state.theme.colorTheme);

  return (
    <div
      className={styles.card}
      key={card.id}
      style={colorThemeRedux ? { height: "auto" } : {}}
    >
      <div className={styles.photo}>
        <img src={card.photo} alt={card.title} />
        <VoiceAudioButton link={card.voice} />
      </div>
      <div className={styles.text}>
        <p
          className={styles.name}
          style={
            colorThemeRedux
              ? {
                  color: "#000",
                  fontSize: 30,
                  marginBottom: 20,
                  lineHeight: 1.1,
                }
              : {}
          }
        >
          {card.fio.split(" ")[0]}
          <br />
          {card.fio.split(" ").slice(1).join(" ")}
        </p>
        {card.position && (
          <p
            className={styles.jobtitle}
            style={
              colorThemeRedux
                ? {
                    color: "#000",
                    fontSize: 30,
                    marginBottom: 20,
                    lineHeight: 1.1,
                  }
                : {}
            }
          >
            {card.position}
          </p>
        )}
        <p
          className={styles.about}
          style={
            colorThemeRedux
              ? {
                  color: "#000",
                  fontSize: 30,
                  marginBottom: 20,
                  lineHeight: 1.1,
                }
              : {}
          }
        >
          {card.text}
        </p>
      </div>
    </div>
  )
}

export default ExpertItem;
