/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import styles from "./NewsItem.module.scss";
import PageTemplate from "../../templates/PageTemplate";
import Container from "../../templates/Container";
import Head from "../../elements/Head/Head";

import { ReactComponent as VenzelFive } from "../../../assets/svg/venzel_5_2color.svg";
import { useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getNewsById } from "../../../redux/requests/getNews";
import { MEDIA_BASE_URL } from "../../../redux/API/BASE_URL";

const NewsItem = () => {
  const colorThemeRedux = useSelector((state) => state.theme.colorTheme);
  const [news, setNews] = useState(false);

  const params = useParams();
  const navigate = useNavigate();

  const formateDate = (date) => {
    const dateLocal = new Date(date);

    const day =
      dateLocal.getDate() < 10
        ? "0" + dateLocal.getDate()
        : dateLocal.getDate();

    const month =
      dateLocal.getMonth() + 1 < 10
        ? "0" + (dateLocal.getMonth() + 1)
        : dateLocal.getMonth() + 1;

    return `${day}.${month}.${dateLocal.getFullYear()} `;
  };

  useEffect(() => {
    if (params.id) {
      getNewsById(params.id).then((res) => {
        if (res.detail === "Страница не найдена.") {
          navigate("/news");
          return;
        }
        setNews(res);
      });
    } else {
      navigate("/news");
    }
  }, [params]);

  const createMarkup = (text) => {
    const strWithImg = text.replaceAll("/media", `${MEDIA_BASE_URL}media`);
    return { __html: strWithImg };
  };

  return (
    <>
      <PageTemplate>
        <Head />

        <Container>
          <section className={styles.history}>
            <h1
              className={styles.title}
              style={colorThemeRedux ? { fontSize: 30, color: "#000" } : {}}
            >
              {news?.title}
            </h1>
            {!colorThemeRedux && <div className={styles.separator} />}

            <div
              className={
                styles.frame +
                " " +
                (colorThemeRedux ? styles.lowVisibleVersion : "")
              }
            >
              {news && (
                <div className={styles.content}>
                  <img src={news.photo} alt="" />
                  <div
                    className={styles.full_description}
                    dangerouslySetInnerHTML={createMarkup(
                      news.full_description
                    )}
                  ></div>
                </div>
              )}
              <VenzelFive />
              <VenzelFive />
              <VenzelFive />
              <VenzelFive />

              {news && (
                <div className={styles.control}>
                  Опубликовано {formateDate(news.datetime)}
                  <Link to={"/news"} className={styles.backLink}>
                    назад
                  </Link>
                </div>
              )}
            </div>

            {!colorThemeRedux && (
              <div className={styles.separator + " " + styles.separator_down} />
            )}
          </section>
        </Container>
      </PageTemplate>
    </>
  );
};

export default NewsItem;
