import React from "react";
import { Link, NavLink } from "react-router-dom";
import styles from "./NavMainMenuMobile.module.scss";
import { ReactComponent as VenzelFive } from "../../../../assets/svg/venzel_5_2color.svg";

import LowVisibleButton from "../LowVisibleButton";

const NavMainMenuMobile = ({ burgerActive, setBurgerActive }) => {
  return (
    <>
      <div style={{ opacity: 0 }}>
        <LowVisibleButton />
      </div>
      <nav
        className={burgerActive ? styles.nav + " " + styles.active : styles.nav}
        onClick={() => {
          burgerActive ? setBurgerActive(false) : setBurgerActive(true);
        }}
      >
        <ul className={styles.list} onClick={(e) => e.stopPropagation()}>
          <li className={styles.item}>
            <NavLink
              to="/"
              className={({ isActive }) =>
                (isActive ? styles.pressed : "") + " " + styles.link
              }
              onClick={() => {
                burgerActive ? setBurgerActive(false) : setBurgerActive(true);
              }}
            >
              <span>Главная</span>
            </NavLink>
          </li>

          <li className={styles.item}>
            <NavLink
              to="/news"
              className={({ isActive }) =>
                (isActive ? styles.pressed : "") + " " + styles.link
              }
              onClick={() => {
                burgerActive ? setBurgerActive(false) : setBurgerActive(true);
              }}
            >
              <span>Новости</span>
            </NavLink>
          </li>

          <li className={styles.item}>
            <NavLink
              to="/participants"
              className={({ isActive }) =>
                (isActive ? styles.pressed : "") + " " + styles.link
              }
              onClick={() => {
                burgerActive ? setBurgerActive(false) : setBurgerActive(true);
              }}
            >
              <span>Участники</span>
            </NavLink>
          </li>

          <li className={styles.item}>
            <NavLink
              to="/gallery"
              className={({ isActive }) =>
                (isActive ? styles.pressed : "") + " " + styles.link
              }
              onClick={() => {
                burgerActive ? setBurgerActive(false) : setBurgerActive(true);
              }}
            >
              <span>Медиа</span>
            </NavLink>
          </li>

          <li className={styles.item}>
            <NavLink
              to="/history"
              className={({ isActive }) =>
                (isActive ? styles.pressed : "") + " " + styles.link
              }
              onClick={() => {
                burgerActive ? setBurgerActive(false) : setBurgerActive(true);
              }}
            >
              <span>История Фестиваля</span>
            </NavLink>
          </li>

          <li className={styles.item + " " + styles.file}>
            <Link
              to={require("../../../../assets/files/Programma.pdf")}
              target="_blank"
              download={true} rel="noreferrer"
            >
              <span>Программа Фестиваля</span>
            </Link>
          </li>

          <li className={styles.item}>
            <NavLink
              to="/press"
              className={({ isActive }) =>
                (isActive ? styles.pressed : "") + " " + styles.link
              }
              onClick={() => {
                burgerActive ? setBurgerActive(false) : setBurgerActive(true);
              }}
            >
              <span>Пресс-релиз</span>
            </NavLink>
          </li>

          <li className={styles.item}>
            <NavLink
              to="/contacts"
              className={({ isActive }) =>
                (isActive ? styles.pressed : "") + " " + styles.link
              }
              onClick={() => {
                burgerActive ? setBurgerActive(false) : setBurgerActive(true);
              }}
            >
              <span>Контакты</span>
            </NavLink>
          </li>

          <li className={styles.item + " " + styles.file}>
            <Link
              to={require("../../../../assets/files/polozhenie.pdf")}
              target="_blank"
              download={true} rel="noreferrer"
            >
              <span>Положение о фестивале</span>
            </Link>
          </li>

          <li className={styles.decorate}>
            <VenzelFive />
            <VenzelFive />
            <VenzelFive />
            <VenzelFive />
          </li>
        </ul>
      </nav>
      <div
        className={
          burgerActive ? styles.overlay + " " + styles.active : styles.overlay
        }
        onClick={() => {
          burgerActive ? setBurgerActive(false) : setBurgerActive(true);
        }}
      />
    </>
  );
};

export default NavMainMenuMobile;
