import { Routes, Route } from "react-router-dom";
import "./App.scss";
import Header from "./components/elements/Header/Header";
import MainPage from "./components/pages/MainPage/MainPage";
import History from "./components/pages/History/History";
import Contacts from "./components/pages/Contacts/Contacts";
import Participants from "./components/pages/Participants/Participants";
import SingleParticipant from "./components/pages/Participants/SingleParticipant/SingleParticipant";
import Media from "./components/pages/Media/Media";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeThemeAction } from "./redux/reducers/ColorThemeReducer";
import Modal from "./components/elements/Header/Modal/Modal";
import Press from "./components/pages/Press/Press";
import News from "./components/pages/News/News";
import NewsItem from "./components/pages/NewsItem/NewsItem";

function App() {
  const [isShowModal, setShowModal] = useState(false);
  const colorThemeRedux = useSelector((state) => state.theme.colorTheme);

  const dispatch = useDispatch();

  const isMobile = () => {
    if (window.innerWidth < 1000) {
      if (colorThemeRedux === true) {
        dispatch(changeThemeAction(false));
        setShowModal(true);
        return;
      }
    }
    if (window.innerWidth >= 1000) setShowModal(false);
  };

  useEffect(() => {
    window.addEventListener("resize", isMobile);

    return () => window.removeEventListener("resize", isMobile);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [colorThemeRedux]);
  return (
    <>
      {isShowModal && <Modal close={() => setShowModal(false)} />}

      <Header />
      <Routes>
        <Route exact path="/" element={<MainPage />} />

        <Route exact path="/history" element={<History />} />
        <Route exact path="/contacts" element={<Contacts />} />
        <Route exact path="/participants" element={<Participants />} />
        <Route path="/participants/:id" element={<SingleParticipant />} />
        <Route exact path="/gallery" element={<Media />} />
        <Route exact path="/press" element={<Press />} />
        <Route exact path="/news" element={<News />} />
        <Route exact path="/news/:id" element={<NewsItem />} />
      </Routes>
    </>
  );
}

export default App;
