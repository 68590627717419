import React from "react";
import styles from "./Modal.module.scss";

const Modal = ({ close }) => {
  return (
    <div className={styles.modalWrapper}>
      <div className={styles.modal}>
        <p>
          Чтобы воспользоваться версией для слабовидящих, откройте сайт на
          экране монитора компьютера
        </p>
        <p
          onClick={close}
          style={{
            marginTop: 20,
            textDecoration: "underline",
            cursor: "pointer",
          }}
        >
          Закрыть
        </p>
      </div>
    </div>
  );
};

export default Modal;
