/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
import React, { useState, useEffect } from "react";
import styles from "./Participants.module.scss";
import PageTemplate from "../../templates/PageTemplate";
import Container from "../../templates/Container";

import { getParticipants } from "../../../redux/requests/getParticipants";
import { Link } from "react-router-dom";
import Head from "../../elements/Head/Head";

import { useSelector } from "react-redux";

const Participants = () => {
  const [data, setData] = useState();
  const [dataToShow, setDataToShow] = useState();
  const [years, setYears] = useState([]);
  const [activeYear, setActiveYear] = useState();
  function createMarkup(title) {
    return {__html: title};
  }

  useEffect(() => {
    getParticipants().then((res) => {
      const yearsArray = [];
      res.results.forEach((item) => {
        if (!yearsArray.includes(item.year)) {
          yearsArray.push(item.year);
        }
      });
      yearsArray.sort((a, b) => {
        if (a > b) return -1;
        if (a == b) return 0;
        if (a < b) return 1;
      });
      setYears(yearsArray);
      setActiveYear(yearsArray[0]);
      setData(res);
    });
  }, []);

  useEffect(() => {
    if (!activeYear || !data) return;
    setDataToShow(data.results.filter((item) => item.year === activeYear));
  }, [activeYear, data]);

  const colorThemeRedux = useSelector((state) => state.theme.colorTheme);

  return (
    <>
      <PageTemplate>
        <Head />

        <Container>
          <section className={styles.participants}>
            <h1
              className={styles.section_title}
              style={colorThemeRedux ? { fontSize: 30, color: "#000" } : {}}
            >
              Участники
            </h1>
            {years.length > 0 && (
              <div
                className={
                  styles.yearsWrap +
                  " " +
                  (colorThemeRedux ? styles.lowVisibleYears : "")
                }
              >
                {years.map((item, index) => (
                  <div
                    className={styles.years}
                    key={index}
                    onClick={() => setActiveYear(item)}
                  >
                    <button
                      style={
                        item === activeYear
                          ? {
                              backgroundColor: "rgba(174, 150, 124, 0.4)",
                              boxShadow:
                                "0 0 0 2px #ae967c, 0 0 0 4px #2e1005, 0 0 7px 3px #e3d1ad",
                            }
                          : {}
                      }
                    >
                      {item}
                    </button>
                  </div>
                ))}
              </div>
            )}
            {!colorThemeRedux && <div className={styles.separator} />}

            <div
              className={styles.cards}
              style={
                colorThemeRedux ? { gridTemplateColumns: "repeat(2, 1fr)" } : {}
              }
            >
              {dataToShow
                // ?.sort((a, b) => (a.title > b.title ? 1 : -1))
                ?.map((item) => (
                  <Link
                    to={"/participants/" + item.id}
                    className={
                      styles.card +
                      " " +
                      (colorThemeRedux ? styles.lowVisibleVersion : "")
                    }
                    key={item.id}
                  >
                    <div className={styles.photo}>
                      <img src={item.image} alt={item.title} />
                    </div>
                    {/* <p className={styles.title}>{item.title}</p> */}
                    <p className={styles.title} dangerouslySetInnerHTML={createMarkup(item.title)} />
                  </Link>
                ))}
            </div>
          </section>
        </Container>
      </PageTemplate>
    </>
  );
};

export default Participants;
