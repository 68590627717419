import React, { useState, useEffect } from 'react';
import styles from './Contacts.module.scss';
import PageTemplate from '../../templates/PageTemplate';
import Container from '../../templates/Container';
import Head from '../../elements/Head/Head';
import Socials from '../../elements/Socials/Socials';

import { getContacts } from '../../../redux/requests/getContacts';

import { ReactComponent as VenzelFive } from '../../../assets/svg/venzel_5_2color.svg';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const Contacts = () => {
  const [data, setData] = useState();
  useEffect(() => {
    getContacts().then((res) => {
      setData(res);
    });
  }, []);

  const colorThemeRedux = useSelector((state) => state.theme.colorTheme);

  return (
    <>
      <PageTemplate>
        <Head />

        <Container>
          <section className={styles.contacts}>
            <h1
              className={styles.section_title}
              style={colorThemeRedux ? { fontSize: 30, color: '#000' } : {}}
            >
              Контакты
            </h1>
            {!colorThemeRedux && <div className={styles.separator} />}

            <div
              className={
                styles.frame +
                ' ' +
                (colorThemeRedux ? styles.lowVisibleVersion : '')
              }
            >
              <div className={styles.wrapper}>
                {data?.results.map((item) => (
                  <div className={styles.item} key={item.id}>
                    <p className={styles.title}>{item.title}</p>
                    <p className={styles.name}>{item.fio}</p>
                    <Link to={`tel:+${item.phone}`} className={styles.phone}>
                      <span>{item.phone}</span>
                    </Link>
                    <p className={styles.email}>{item.email}</p>
                  </div>
                ))}
              </div>

              <VenzelFive />
              <VenzelFive />
              <VenzelFive />
              <VenzelFive />
            </div>

            {!colorThemeRedux && (
              <div className={styles.separator + ' ' + styles.separator_down} />
            )}
          </section>
        </Container>

        <Socials />
      </PageTemplate>
    </>
  );
};

export default Contacts;
