import React, { useEffect } from "react";
import styles from "./NavMainMenu/NavMainMenu.module.scss";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { changeThemeAction } from "../../../redux/reducers/ColorThemeReducer";

import { ReactComponent as EyeIcon } from "../../../assets/svg/eye.svg";
import { ReactComponent as EyeIconBlack } from "../../../assets/svg/eye-black.svg";
import { useLocation } from "react-router-dom";

function LowVisibleButton() {
  // const [colorTheme, setColorTheme] = useState(false);
  const colorThemeRedux = useSelector((state) => state.theme.colorTheme);

  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const changeColorVersion = () => {
    if (window.innerWidth <= 1000) return;
    colorThemeRedux
      ? dispatch(changeThemeAction(false))
      : dispatch(changeThemeAction(true));
  };

  // useEffect(() => {
  //   setColorTheme(colorThemeRedux);
  // }, [colorThemeRedux]);

  useEffect(() => {
    const body = document.body;
    const p = document.querySelectorAll("p");
    const a = document.querySelectorAll("a");

    if (colorThemeRedux) {
      body.style.backgroundColor = "#fff";
      p.forEach((item) => {
        item.style.fontSize = "30px";
        item.style.color = "#000";
        item.style.lineHeight = 1.3;
      });
      a.forEach((item) => {
        item.style.fontSize = "30px";
        item.style.color = "#000";
        item.style.lineHeight = 1.3;
      });
    } else {
      body.style.backgroundColor = "";
      p.forEach((item) => {
        item.style.fontSize = "";
        item.style.color = "";
        item.style.lineHeight = "";
      });
      a.forEach((item) => {
        item.style.fontSize = "";
        item.style.color = "";
        item.style.lineHeight = "";
      });
    }
  }, [colorThemeRedux, pathname]);
  return (
    <>
      <li
        className={styles.lowVisibleButton}
        onClick={changeColorVersion}
        style={
          colorThemeRedux
            ? {
                top: "100%",
                backgroundColor: "#fff",
                padding: "0px 40px",
                width: "100%",
                justifyContent: "flex-end",
              }
            : {}
        }
      >
        <div
          style={
            colorThemeRedux
              ? {
                  top: "100%",
                  backgroundColor: "#fff",
                  padding: "0px 40px",
                }
              : {}
          }
        >
          {colorThemeRedux ? <EyeIconBlack /> : <EyeIcon />}

          <p>
            {colorThemeRedux
              ? "Обычная версия сайта"
              : "Версия для слабовидящих"}
          </p>
        </div>
      </li>
    </>
  );
}

export default LowVisibleButton;
