import React, { useEffect, useState } from "react";
import styles from "./AboutState.module.scss";
import Container from "../../../templates/Container";
import Krsk from "../../../../assets/image/krsk.jpg";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { getVoises } from "../../../../redux/requests/getVoises";
import VoiceAudioButton from "../../../elements/VoiceAudioButton/VoiceAudioButton";

const AboutState = () => {
  const colorThemeRedux = useSelector((state) => state.theme.colorTheme);

  let location = useLocation();
  const [voice, setVoice] = useState({});

  useEffect(() => {
    getVoises().then((res) => {
      setVoice(res.results[0].voice);
    });
  }, [location]);

  return (
    <section className={styles.aboutkr} id="aboutkr">
      <Container>
        <h2
          className={styles.title}
          style={colorThemeRedux ? { fontSize: 30, color: "#000" } : {}}
        >
          Красноярский край
        </h2>
        {!colorThemeRedux && <div className={styles.separator} />}

        <p className={styles.wrapper}>

          <div className={styles.image}>
            <VoiceAudioButton link={voice} />
            <img src={Krsk} alt="" width="350" height="240" />
          </div>

          <span
            style={
              colorThemeRedux
                ? {
                    fontSize: 30,
                    color: "#000",
                    lineHeight: 1.1,
                    letterSpacing: 1.2,
                  }
                : {}
            }
          >
            {" "}
            Красноярский край — географический центр России. Второй по величине
            регион страны, занимающий 2 339,7 тыс. кв. км. Территория региона
            простирается вдоль великой реки Енисей на 3 000 км с севера на юг.
            Енисей — самая полноводная российская река, занимающая по площади
            бассейна второе место в стране и седьмое место в мире. Именно по
            этой реке проходит граница между Западной и Восточной Сибирью.
          </span>
          <span
            style={
              colorThemeRedux
                ? {
                    fontSize: 30,
                    color: "#000",
                    lineHeight: 1.1,
                    letterSpacing: 1.2,
                  }
                : {}
            }
          >
            {" "}
            Столица региона – Красноярск: мегаполис посреди тайги. Город основан
            в 1628 году отрядом казаков во главе с Андреем Дубенским как военный
            острог. Статус города Красноярск получил в 1690 году, когда Сибирь
            была окончательно присоединена к России. В 2028 году город-миллионник
            Красноярск отпразднует свой 400-летний юбилей. Визитная карточка
            столицы края — уникальный национальный парк «Красноярские Столбы»,
            расположенный на правом берегу Енисея. Это удивительный уголок
            природы, где причудливые скалы окружены ожерельем горной тайги.
          </span>
          <span
            style={
              colorThemeRedux
                ? {
                    fontSize: 30,
                    color: "#000",
                    lineHeight: 1.1,
                    letterSpacing: 1.2,
                  }
                : {}
            }
          >
            {" "}
            В последние годы в Красноярске развивается и современная
            инфраструктура, создаются новые объекты, привлекающие горожан и
            путешественников. К уникальным туристским объектам на территории
            края относятся: самая длинная лестница России на Торгашинском хребте
            близ Красноярска, смотровая площадке на самой высокой точке краевого
            центра – Николаевской сопке, эко-парк «Гремячая грива», смотровая на
            Манской петле, набережная в городе Дивногорск.
          </span>
        </p>
      </Container>
    </section>
  );
};

export default AboutState;
