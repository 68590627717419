import React from "react";
import styles from "./Container.module.scss";
import UseWindowSize from "./UseWindowSize/UseWindowSize";

const Container = ({ children }) => {
  const [width] = UseWindowSize();
  return (
    <div
      className={
        styles.container +
        " " +
        (width < 1290 && width >= 768 ? styles.tablet : "") +
        " " +
        (width < 768 ? styles.mobile : "")
      }
    >
      {children}
    </div>
  );
};

export default Container;
