import React, { useRef } from 'react'
import { voiceUrlFormate } from '../../../utils/vioceUrlFormat'
import { useDispatch, useSelector } from 'react-redux';
import { playAudioAction, stopAudioAction } from '../../../redux/reducers/AudioReducer';
import classnames from '../../../utils/classnames';
import styles from "./VoiceAudioButton.module.scss";
import { ReactComponent as Play } from "../../../assets/svg/play.svg";
import { ReactComponent as Pause } from "../../../assets/svg/pause.svg";

const VoiceAudioButton = ({ link }) => {
  const colorThemeRedux = useSelector((state) => state.theme.colorTheme);
  const audioRedux = useSelector((state) => state.audio.audio);
  const dispatch = useDispatch();
  const audioRef = useRef(null);
  const isCurrentPlayed = audioRedux?.src === voiceUrlFormate(link);

  const handlePlayClick = () => {
    if (!audioRef.current) return
    if (audioRedux && !audioRedux.paused) {
      audioRedux.pause();
      audioRef.current.currentTime = 0;
      dispatch(stopAudioAction());
    }
    audioRef.current.play();
    dispatch(playAudioAction(audioRef.current));
  }

  const handlePauseClick = () => {
    if (!audioRef.current) return
    audioRef.current?.pause();
    audioRef.current.currentTime = 0;
    audioRedux?.pause();
    dispatch(stopAudioAction());
  }

  return (
    <div className={classnames([styles.voice, [styles.played, isCurrentPlayed], [styles.lowVisible, colorThemeRedux]])}>
      <audio
        src={voiceUrlFormate(link)}
        ref={audioRef}
      />
      <span
        className={classnames([styles.play, styles.button, [styles.lowVisible, colorThemeRedux]])}
        onClick={handlePlayClick}><Play />
      </span>
      <span
        className={classnames([styles.pause, styles.button, [styles.lowVisible, colorThemeRedux]])}
        onClick={handlePauseClick}><Pause />
      </span>
    </div>
  )
}

export default VoiceAudioButton;
