const initialState = {
  audio: null,
};

const PLAY_AUDIO = "PLAY_AUDIO";
const STOP_AUDIO = "STOP_AUDIO";


export const AudioReducer = (state = initialState, action) => {
  switch (action.type) {
    case PLAY_AUDIO:
      return {
        audio: action.payload,
      };

    case STOP_AUDIO:
      return {
        audio: null,
      };

    default:
      return state;
  }
};

export const playAudioAction = (payload) => ({
  type: PLAY_AUDIO,
  payload: payload,
});

export const stopAudioAction = () => ({
  type: STOP_AUDIO,
});
