import React, { useEffect, useState } from "react";
import styles from "./News.module.scss";
import PageTemplate from "../../templates/PageTemplate";
import Container from "../../templates/Container";
import Head from "../../elements/Head/Head";

import { ReactComponent as VenzelFive } from "../../../assets/svg/venzel_5_2color.svg";
import { useSelector } from "react-redux";
import SingleNews from "./SingleNews/SingleNews";
import { getNews } from "../../../redux/requests/getNews";

const News = () => {
  const colorThemeRedux = useSelector((state) => state.theme.colorTheme);
  const [news, setNews] = useState([]);

  useEffect(() => {
    getNews().then((res) => {
      setNews(res.results);
    });
  }, []);

  return (
    <>
      <PageTemplate>
        <Head />

        <Container>
          <section className={styles.history}>
            <h1
              className={styles.title}
              style={colorThemeRedux ? { fontSize: 30, color: "#000" } : {}}
            >
              Новости
            </h1>
            {!colorThemeRedux && <div className={styles.separator} />}

            <div
              className={
                styles.frame +
                " " +
                (colorThemeRedux ? styles.lowVisibleVersion : "")
              }
            >
              {news?.map((item, index) => (
                <SingleNews key={index} news={item} />
              ))}

              <VenzelFive />
              <VenzelFive />
              <VenzelFive />
              <VenzelFive />
            </div>

            {!colorThemeRedux && (
              <div className={styles.separator + " " + styles.separator_down} />
            )}
          </section>
        </Container>
      </PageTemplate>
    </>
  );
};

export default News;
