import React from "react";
import { useLocation } from "react-router-dom";
import styles from "./PageTemplate.module.scss";
import { useSelector } from "react-redux";

const PageTemplate = ({ children }) => {
  const location = useLocation();

  const colorThemeRedux = useSelector((state) => state.theme.colorTheme);

  return (
    <div
      className={
        styles.PageTemplate +
        " " +
        (location.pathname === "/" ? styles.main : "")
      }
    >
      <main
        style={{ flex: "1 1 auto" }}
        className={colorThemeRedux ? styles.lowVisibleVersion : ""}
      >
        {children}
      </main>
    </div>
  );
};

export default PageTemplate;
