import React, { useState, useEffect } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import styles from "./SingleParticipant.module.scss";
import PageTemplate from "../../../templates/PageTemplate";
import Container from "../../../templates/Container";
import Head from "../../../elements/Head/Head";
import { getSingleParticipant } from "../../../../redux/requests/getSingleParticipant";
import { ReactComponent as VenzelFive } from "../../../../assets/svg/venzel_5_2color.svg";
import { useSelector } from "react-redux";
import VoiceAudioButton from "../../../elements/VoiceAudioButton/VoiceAudioButton.jsx";

const SingleParticipant = () => {
  let participant = useParams();
  let location = useLocation();
  let [data, setData] = useState({});
  function createMarkup(title) {
    return {__html: title};
  }

  const colorThemeRedux = useSelector((state) => state.theme.colorTheme);

  useEffect(() => {
    getSingleParticipant(participant.id).then((res) => {
      setData(res);
    });
  }, [location, participant.id]);

  return (
    <>
      <PageTemplate>
        <Head />

        <Container>
          <div className={styles.partisipant}>
            <h1 className="visually-hidden">{data?.title}</h1>

            <div
              className={
                styles.frame +
                " " +
                (colorThemeRedux ? styles.lowVisibleVersion : "")
              }
            >
              <section
                className={
                  styles.card +
                  " " +
                  (colorThemeRedux ? styles.lowVisibleCard : "")
                }
              >
                <div className={styles.photo}>
                  {data?.voice && <VoiceAudioButton link={data?.voice} />}
                  <img src={data?.image} alt={data?.title} />
                </div>

                <p className={styles.section_title} dangerouslySetInnerHTML={createMarkup(data?.title)} />
                <div className={styles.about}>
                  <div className={styles.director}>
                    <p>
                      <span>{data?.director}</span>
                    </p>

                    {data?.link && (
                      <Link to={data?.link} target="_blank" rel="noreferrer">
                        Репертуар
                      </Link>
                    )}
                  </div>

                  <div className={styles.info}>
                    <div className={styles.locate}>
                      <p>
                        <span>Страна, город: </span>
                      </p>

                      <p>
                        <span>{data?.country}</span>
                        {", "}
                        <span>{data?.city}</span>
                      </p>
                    </div>
                    <div className={styles.status}>
                      <span>Форма участия: </span>
                      <span>{data?.get_participation_display}</span>
                    </div>
                  </div>
                </div>
                <p className={styles.desc}>{data?.description}</p>
              </section>
              {!colorThemeRedux && (
                <>
                  <VenzelFive />
                  <VenzelFive />
                  <VenzelFive />
                  <VenzelFive />
                </>
              )}
            </div>
          </div>
        </Container>
      </PageTemplate>
    </>
  );
};

export default SingleParticipant;
