import React from 'react';
import styles from './Burger.module.scss';

const Burger = ({ burgerActive, setBurgerActive, scroll }) => {
  return (
    <button className={styles.burger + ' ' +
    (burgerActive ? styles.burger__pressed : '') + ' ' +
    (scroll ? styles.burger__up : '')
  }
      onClick={(() => {burgerActive ? setBurgerActive(false) : setBurgerActive(true)})}>
      <span />
      <span />
      <span />
      <span />
      <span className="visually-hidden">бургер меню</span>
    </button>
  );
};

export default Burger;