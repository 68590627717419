import { BASE_URL } from '../API/BASE_URL';

export const getContacts = async () => {
  const response = await fetch(BASE_URL + 'contacts/contacts/', {
    method: 'GET',
  });

  const data = await response.json();

  return data;
};
